import instance from "../Services";
import botInstance from "../Services/BotIndex";
import "../App.css";
import axios from "axios";

import Swal from "sweetalert2";
import QbotInstance from "../Services/QuobotoIndex";
import SCInstance from "../Services/SCIndex";
import { useTranslation } from "react-i18next";

export const notify = (status, msg) => {
  Swal.fire({
    position: "center",
    icon: status == true ? "success" : "error",
    title: msg,
    showConfirmButton: false,
    timer: 1500,
  });
};
export const notify1 = (status, msg) => {
  Swal.fire({
    position: "center",
    icon: status == true ? "success" : "error",
    title: msg,
    // text:msg,
    showConfirmButton: true,

  });
};

export const dilogueBox = (text, onClickFn) => {
  Swal.fire({
    title: text,
    showCancelButton: true,
    confirmButtonText: 'Yes',
  }).then((result) => {

    if (result.isConfirmed) {
      onClickFn()
    }
  })
}
export const dilogueBox2 = (text, onClickFn, label, reason) => {

  Swal.fire({
    title: text,
    input: 'text', // Input field type (text, email, password, etc.)
    showCancelButton: true,
    confirmButtonText: 'Yes',
    inputLabel: label, // Label for the input field
    inputPlaceholder: label,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      const inputValue = result.value; // Get the value entered in the input field
      onClickFn(inputValue);
    }
  })
}


// export const notify = (status, msg) => {
//   toast.configure();
//   if (status == true) {
//     toast.success(msg, { position: toast.POSITION.BOTTOM_CENTER });
//   } else {
//     toast.error(msg, { position: toast.POSITION.BOTTOM_CENTER });
//   }
// };

export const getList = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      if (res.data.data?.length == undefined) {
        return [];
      } else {
        return res.data.data;
      }
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const getById = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && Object.keys(res?.data?.data)?.length > 0) {
      return res?.data?.data;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};


export const save = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};


export const saveWithConfirmation = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify1(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};
export const saveWithoutMessage = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

export const saveWithoutMessageWithoutLoad = async (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  };
  try {
    let res = await axios?.post(`${process.env.REACT_APP_API_URL}${url}`, data, { headers });
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};


export const botResponce = async (url, data) => {
  try {
    let res = await botInstance.post(url, data);
    return res;
  } catch (e) {
  }
};

export const SCResponce = async (url, data) => {
  try {
    let res = await SCInstance.post(url, data);
    return res;
  } catch (e) { console.log(e) }
};

export const SCGetResponce = async (url, data) => {
  try {
    let res = await SCInstance.get(url, data);
    return res;
  } catch (e) { console.log(e) }
};



export const postMethod = async (url, data) => {
  try {
    let res = await QbotInstance.post(url, data);
    if (res?.data?.status == true) {
      return res?.data?.data;
    } else {
      return "";
    }
  } catch (e) {
    console.log(e);
    return "";
  }
};
